@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital@0;1&family=Rubik:ital,wght@0,700;1,700&display=swap');

:root {
  
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  --background-color: rgb(24, 24, 27);
  --on-background-color: rgba(255, 255, 255, 0.87);
  --off-white-color: #FEF;
  
  --primary-color: #D21087;
  --secondary-color: #5B3D93;
  --accent-color: #5fa8d7;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#main-body {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  min-height: 100vh;
  color: var(--on-background-color);
  background: var(--background-color);
  overflow-x: hidden;
  flex: 1;

  .wrapper {
    min-height: calc(100vh - 175px);
    height: auto;
  }
}

h1, h2, h3, h4 {
  font-family: "Rubik", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: var(--on-background-color);
}

p, a, button, label, input, textarea, span, div, h5, h6 {
  font-family: "Libre Franklin", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  color: var(--on-background-color);
}