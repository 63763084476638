.testimonials-container {
    padding: 128px 0 32px 0;

    margin: 0 auto;
    display: flex;
    flex-direction: column;

    width: 60%;
    min-height: 800px;
    height: auto;

    @media(max-width: 864px) {
        width: 100%;
        justify-content: center;
        padding: 128px 0 128px 0;
    }

    .testimonials-container__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        h3 {
            font-size: 2rem;
        }

        h4 {
            font-size: 1rem;
            background: -webkit-linear-gradient(135deg, var(--secondary-color), var(--off-white-color));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 32px;
        }

        @media(max-width: 864px) {
            h3, h4 {
                width: 80%;
                text-align: center;
                margin: 0 auto;
            }

            h3 {
                font-size: 1.5rem;
            }
        }
    }

    .testimonials-container__list {
        width: 100%;
        min-height: 800px;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .tcol {
            width: 33%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 100%;

            &.tcol-1 {
                margin-top: 64px;
            }

            @media(max-width: 1024px) {
                width: 45%;

                &.tcol-1 {
                    margin-top: 0;
                }
            }

            @media(max-width: 864px) {
                width: 85%;
                
                &.tcol-1 {
                    margin-top: 0;
                }
            }
        }

        @media(max-width: 864px) {
            justify-content: center;
            min-height: 800px;
            height: auto;

            .tcol {
                height: auto;
            }
        }
    }
}


.testimonial {
    background-color: #27272a80;
    border: 1px solid #52525b99;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    width: 95%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, var(--background-color) 0.3s;
    min-height: 300px;
    height: auto;

    @media(max-width: 864px) {
        min-height: 225px;
    }

    .testimonial__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 0.25px solid #52525b99;

        /* img not in use*/
        /*img {
            height: 75px;
            width: 75px;
            color: white;
            fill: white;
            filter: invert(100%) sepia(100%) saturate(25%) hue-rotate(0deg) brightness(100%) contrast(100%);
            padding: 8px;
            border: 1px solid var(--off-white-color);
            border-radius: 50%;
            margin-right: 16px;
        }*/

        .person-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: calc(100% - 100px);

            h5, p {
                width: 100%;
            }

            h5 {
                font-size: 1.25rem;
                font-weight: 800;
            }

            p {
                font-size: 0.75rem;
                font-style: italic;
            }
        }
    }

    .testimonial__body {
        p {
            line-height: 1.75;
        }
    }

    &:hover {
        transform: translateY(-4px);
        border-color: var(--accent-color);

        .testimonial__top {
            border-color: var(--accent-color)
        }
        
    }

}