footer {
    height: 300px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @media(max-width: 864px) {
        min-height: 450px;
        height: auto;
        padding-top: 64px;
    }

    .information {
        height: 200px;
        width: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        
        .eidolon {
            
            width: 30%;
            display: flex;
            flex-direction: row;
            align-items: center;

            @media(max-width: 864px) {
                justify-content: center;
            }
            
            img {
                height: 100px;
                width: auto;
            }

            p {
                font-size: 1.75rem;
            }
        }

        .links {
            width: 60%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;

            .col {
                display: flex;
                flex-direction: column;
                margin: 0 32px;

                h6 {
                    font-size: 1.25rem;
                }

                a {
                    font-size: 1rem;
                    line-height: 2;
                }
            }
        }

        @media(max-width: 864px) {
            flex-direction: column;
            height: auto;
            min-height: 300px;
            
            .links, .eidolon {
                width: 100%;
            }
        }
    }
    
    .copyright {
        width: 100%;
        height: 50px;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        p {
            padding-top: 32px;
            border-top: 0.25px solid #52525b99;
            width: 60%;
            margin: 0 auto;
        }

        @media(max-width: 864px) {
            align-items: center;
            justify-content: center;
            height: 100px;
        }
    }
}