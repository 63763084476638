.pricing-container {
    width: 100vw;
    min-height: 75vh;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media(max-width: 864px) {
      height: auto;
      min-height: 80vh;
    }
}

.pricing-container__top {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h3 {
        padding-bottom: 64px;
        font-size: 2rem;
    }

    h4 {
        font-size: 1rem;
        background: -webkit-linear-gradient(135deg, var(--secondary-color), var(--off-white-color));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 32px;
    }

    @media(max-width: 864px) {
      h3, h4 {
          width: 80%;
          text-align: center;
          margin: 0 auto;
      }

      h3 {
          font-size: 1.5rem;
      }
  }
}

.pricing-container__body {
  display: flex;
  justify-content: center;
  width: 60%;

  @media(max-width: 864px) {
    width: 85%;
    flex-wrap: wrap;
}
}

.pricing-container__notes {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 58%;
  margin: 0 auto;

  p {
    font-size: 0.75rem;
  }
}

.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

/* Pricing Card Styles */
.pricing-card {
  background-color: #2a2a2a;
  border-radius: 12px;
  padding: 30px;
  margin: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  width: 33%;

  @media(max-width: 864px) {
    width: 85%;
  }
}

.pricing-card.popular {
  /*border: 0.25px solid var(--accent-color);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);*/
  
/*  border: 1px solid #444;*/
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
}

.pricing-title {
  font-size: 1.55rem;
  color: var(--accent-color);
  margin-bottom: 10px;
}

.pricing-description {
  font-style: italic;
  margin-bottom: 15px;
  color: #ccc;
}

.pricing-price {
  font-size: 1.85rem;
  margin: 20px 0;
}

.pricing-features {
  list-style: none;
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
}

.pricing-feature {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.get-started-btn {
  display: inline-block;
  padding: 12px 25px;
  background-color: transparent;
  color: var(--off-white-color);
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin-top: auto;
}

.get-started-btn:hover {
  background-color: darken(var(--primary-color), 10%);
  border: 1px solid var(--accent-color);
}