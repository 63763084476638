.keypoints-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 60%;
    min-height: 400px;
    height: auto;

    @media(max-width: 864px) {
        width: 100%;
        justify-content: center;
    }

    .keypoint {
        width: 32%;
        height: 200px;

        @media(max-width: 1024px) {
            width: 45%;
        }

        @media(max-width: 864px) {
            width: 85%;
        }

        .keypoint__top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            height: 30%;

            .icon-container {
                width: 15%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 50%;
                    width: auto;
                    color: var(--secondary-color);
                }
            }

            h4 {
                width: 75%;
            }
        }

        .keypoint__body {
            height: 70%;
            margin: 0 3.5%;
        }
    }
}