
.hero-container {
    width: 100vw;
    height: 50vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 80%;
    width: 82.5%;
    border-radius: 8px;
}

.hero-content {
    position: relative;
    z-index: 1;
    padding: 20px;
}

.hero-slogan {
    font-size: 4.5rem;
    margin: 0 0 10px 0;
    font-weight: 800;
    background: -webkit-linear-gradient(15deg, var(--accent-color), var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--accent-color) !important;
    .color-accent {
        font-weight: 800;
        /* color: var(--accent-color) !important; */
    }

    .color-primary {
        margin-left: 16px;
        font-weight: 800;
        /* color: var(--primary-color); */
    }
    
    .color-secondary {
        margin-left: 16px;
        font-weight: 800;
        /* color: var(--secondary-color); */
    }
}

.hero-description {
    font-size: 1.25rem;
    margin: 0 0 30px 0;
}

.hero-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.hero-button {
    background-color: var(--secondary-color);
    color: var(--off-white-color);
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.hero-button-secondary {
    background-color: transparent;
    color: var(--on-background-color);
    border: 2px solid var(--secondary-color);
}

.hero-button-secondary:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

@media (max-width: 768px) {
    .hero-slogan {
        font-size: 2rem;
    }

    .hero-description {
        font-size: 1rem;
    }

    .hero-button {
        padding: 8px 16px;
        font-size: 0.875rem;
    }
}

