.scroller-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    border-top: 0.01px solid dimgrey;
    border-bottom: 0.01px solid dimgrey;
    
    .scroller-item {
        height: 100%;
        width: 350px;

        @media(max-width: 864px) {
            width: 175px;
        }

        a {
            width: auto;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 65%;
                width: auto;
                color: #FFF !important;
                fill: #FFF !important;
                filter: invert(100%) sepia(100%) saturate(25%) hue-rotate(0deg) brightness(100%) contrast(100%);

                &.no-invert {
                    filter: none;
                }

                &.png {
                    height: 100%;
                }
            }
        }
    }    
}
