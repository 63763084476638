.introduction-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 100vh;

    .gradient-wrapper {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        h3 {
            padding-bottom: 64px;
            font-size: 2rem;
        }

        h4 {
            font-size: 1rem;
            background: -webkit-linear-gradient(135deg, var(--secondary-color), var(--off-white-color));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 32px;
        }

        img {
            height: 50%;
            width: 50%;
            border-radius: 24%;
        }

        @media(max-width: 864px) {
            h3, h4 {
                width: 80%;
                text-align: center;
                margin: 0 auto;
            }

            h3 {
                font-size: 1.5rem;
            }

            img {
                height: 75%;
                width: 75%;
            }
        }
    }

    @media(max-width: 864px) {
        min-height: 75vh;
    }
}