#navigation {
    width: 100%;
    height: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: var(--background-color);
    color: var(--on-background-color);
    
    .navigation__title {
        
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 75px;
            height: auto;
        }
    }

    .navigation__actions {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            margin: 0 16px;
            text-decoration: none;
            color: var(--off-white-color);
        }

        .link-button {
            padding: 8px 16px;
            background: var(--secondary-color);
            border-radius: 8px;
            color: white;
        }
    }

    @media(max-width: 864px) {
        width: 80%;
        margin: 0 auto;
        .navigation__title {
            width: 50%;
            justify-content: flex-start;
        }
        .navigation__actions {
            width: 50%;
            align-items: flex-end;
            justify-content: flex-end;

            .link-button {
                display: none;
            }
        }
    }
}